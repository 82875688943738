import { Component, OnDestroy } from '@angular/core';
import {
  DisplayField,
  ErrorVersionInfo,
  humanizeVersionString,
  SanitizedError,
  toDisplayFields
} from '@mri-platform/angular-error-handling';
import { merge, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { errorDetailEntries } from './kendo-toast-error-appender.service';

export interface KendoUiErrorState {
  sanitizedMessage: string;
  traceId?: string;
  correlationId?: string;
  versionInfo?: ErrorVersionInfo;
}

@Component({
  template: `
    {{ message }}
    <br />
    @if (!expand && detailFields.length > 0) {
      <div class="details-button" (click)="expandDetails()">Details >></div>
    } @else {
      <div class="details-container">
        <dl>
          @for (field of detailFields; track field) {
            <dt>{{ field.label }}</dt>
            <dd>{{ field.value }}</dd>
          }
        </dl>
        @if (detailFields.length > 0) {
          <button
            (click)="copyToClipboard()"
            aria-label="copy"
            class="mri-icon-button mri-icon-button--small copy-button"
            title="Copy To Clipboard"
          >
            <mri-btn-icon icon="copy" direction="none"></mri-btn-icon>
          </button>
        }
      </div>
    }
    <ng-template #details>
      <div class="details-container">
        <dl>
          @for (field of detailFields; track field) {
            <dt>{{ field.label }}</dt>
            <dd>{{ field.value }}</dd>
          }
        </dl>
        @if (detailFields.length > 0) {
          <button
            (click)="copyToClipboard()"
            aria-label="copy"
            class="mri-icon-button mri-icon-button--small copy-button"
            title="Copy To Clipboard"
          >
            <mri-btn-icon icon="copy" direction="none"></mri-btn-icon>
          </button>
        }
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
      .details-button {
        text-decoration: underline;
        cursor: pointer;
      }
      .details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .copy-button:active {
        transform: scale(1.1);
      }
      dt {
        font-weight: bold;
      }
      dt:after {
        content: ':';
      }
    `
  ]
})
export class KendoUiErrorToastComponent implements OnDestroy {
  private rawDetailFields: DisplayField[] = [];
  detailFields: DisplayField[] = [];
  message = SanitizedError.fallbackMessage;

  set kendoError(value: KendoUiErrorState) {
    this.message = value.sanitizedMessage;

    const [nonVsEntries, vsEntries] = errorDetailEntries(value);
    this.rawDetailFields = toDisplayFields([...nonVsEntries, ...vsEntries]);
    this.detailFields = [
      ...toDisplayFields(nonVsEntries),
      ...toDisplayFields(vsEntries, { valueSelector: humanizeVersionString })
    ];
  }
  expand = false;
  expanded = new Subject<boolean>();
  destroyed = new Subject<boolean>();
  // If the notification has details it will be hidden after 30 secs unless the details button is clicked.
  // Because once the closable is set to true the notification will not hide automatically.
  hide$ = timer(30000).pipe(takeUntil(merge(this.expanded, this.destroyed)));

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  expandDetails() {
    this.expand = true;
    this.expanded.next(true);
  }

  copyToClipboard() {
    const messageField: DisplayField = { label: 'Message', value: this.message };
    const errorText = [messageField, ...this.rawDetailFields]
      .map(({ label, value }) => `${label}: ${value}`)
      .join('\r\n');
    return navigator.clipboard.writeText(errorText);
  }
}
