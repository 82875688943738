import { FactoryProvider } from '@angular/core';
import { ApiConfig, FeatureFlagsConfig, Settings } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';

export const FeatureFlagsConfigProvider: FactoryProvider = {
  provide: FeatureFlagsConfig,
  useFactory: (settings: Settings, apiConfig: ApiConfig, authService: AuthService) =>
    new FeatureFlagsConfig({ ...settings.featureFlags, fetchTrigger$: authService.currentClientId$ }, apiConfig),
  deps: [Settings, ApiConfig, AuthService]
};
