import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@mri-platform/shared/core';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
} else if (environment.dev) {
  // make rxjs-spy library available in dev tools
  // for more information see: https://github.com/cartant/rxjs-spy
  import('rxjs-spy').then(rxjsSpy => {
    console.log(
      '[rxjsSpy] Added to window. To enable logging of tagged observables, at the console window type: `rxjsSpy.create(); spy.log()`'
    );
    console.log('[rxjsSpy] For more information see: https://github.com/cartant/rxjs-spy');
    Object.assign(window, { rxjsSpy });

    // to start observing observables that emit during the initial route navigation uncomment the following code:
    // const spy = rxjsSpy.create();
    // spy.log(/MainNavBarComponent/); // <- example of using a regex pattern to match on specific tag names
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
