@if (vm$ | async; as vm) {
  <mri-shared-drawer [headerTitle]="'Select a different client ID'" [isOpen]="true" (closeDrawer)="closeDrawer()">
    <mri-datalist
      *mriSharedDrawerBody
      [filterFunction]="applyFilter"
      id="clients"
      (selectedItemChange)="selectItem($event)"
      [list]="vm.clients"
      [rowHeight]="49"
      [showRecordCount]="false"
    >
      <mri-datalist-item-header *mriDataListItem="let item" [title]="item.licenseId"></mri-datalist-item-header>
    </mri-datalist>
    <mri-shared-drawer-footer-toolbar
      *mriSharedDrawerFooter
      [canCancel]="true"
      [canSave]="!!vm.selectedClient"
      (cancel)="closeDrawer()"
      (save)="switchClient()"
      saveLabel="Select"
      saveIcon="checkmark"
    ></mri-shared-drawer-footer-toolbar>
  </mri-shared-drawer>
}
