<!--
Breaking these into further components (icon component, item component, etc) breaks some level of the expected layers from the design system.
-->
@if (vm$ | async; as vm) {
  @if (vm.showWidget) {
    <div class="menu-box">
      <div class="widget">
        <ag-widget
          [ApiHost]="this.apiHost"
          [MriClientId]="vm.clientId"
          [debugger]="detailedErrorMessagesWidget"
        ></ag-widget>
      </div>
      <div class="admin" [ngClass]="{ expanded: vm.isNavExpanded, 'logo-text': !vm.headerLogo?.url }">
        @if (vm.headerLogo?.url) {
          <img [src]="vm.headerLogo.url" [alt]="vm.headerLogo.text" />
        } @else {
          <p>{{ vm.headerLogo.text }}</p>
        }
      </div>
    </div>
  }
  <div [ngClass]="{ expanded: vm.isNavExpanded, 'menu-divider': vm.showWidget }"></div>
  @for (item of vm.menuItems; track $index) {
    @let itemIsAskAgora = $any(item).isAskAgora;
    @let itemIsAskAiFrame = $any(item).isAskAgoraFrame;
    @let askAgoraVisibleCheck = itemIsAskAgora && vm.showAskAiWidget;
    @let askAiFrameCheck = itemIsAskAiFrame && vm.showAskAiFrameWidget;

    <!-- Ask Agora or Ask Ai Frame item might not load, or may be taking time to load. currently we do NOT want to show
    a menu item if it is not ready to be used.
    -->
    @let showItem = askAgoraVisibleCheck || askAiFrameCheck || (!itemIsAskAgora && !itemIsAskAiFrame);

    @if (showItem) {
      @if (askAgoraVisibleCheck) {
        <ask-ai
          [showIcon]="false"
          [apiHost]="$any(item).baseApiUrl"
          [assetSource]="$any(item).assetSource"
          [clientId]="vm.clientId"
          [widgetOffset]="vm.isNavExpanded ? 200 : 60"
          [isActivated]="item === vm.activeItem"
          (popupClose)="onAskAgoraClosed(vm.activeItem)"
        ></ask-ai>
      } @else if (askAiFrameCheck) {
        <ask-ai-frame
          [showIcon]="false"
          [clientId]="vm.clientId"
          [baseUrl]="$any(item).baseUrl"
          [frameSources]="$any(item).frameSources"
          [widgetOffset]="vm.isNavExpanded ? 200 : 60"
          [isActivated]="item === vm.activeItem"
          (popupClose)="onAskAgoraFrameClosed(vm.activeItem)"
        ></ask-ai-frame>
      }
      <a
        class="navMenuLink {{ item.cssClass }}"
        [title]="item.title"
        [attr.aria-label]="item.title"
        (click)="clickIcon(item)"
        [ngClass]="{
          active: item !== vm.activePage && item === vm.activeItem,
          activePage: item === vm.activePage,
          expanded: vm.isNavExpanded
        }"
      >
        @if (item !== vm.activePage && item === vm.activeItem) {
          <div class="indicator"></div>
        }
        @if (askAgoraVisibleCheck) {
          <ask-ai-sparkle-button [ngClass]="{ active: item === vm.activeItem }" class="mri-icon__svg navMenuSvg">
          </ask-ai-sparkle-button>
        } @else if (askAiFrameCheck) {
          <ask-ai-frame-sparkle-button [ngClass]="{ active: item === vm.activeItem }" class="mri-icon__svg navMenuSvg">
          </ask-ai-frame-sparkle-button>
        } @else {
          <svg
            [ngClass]="{
              active: item !== vm.activePage && item === vm.activeItem,
              activePage: item === vm.activePage
            }"
            class="mri-icon__svg navMenuSvg"
            viewBox="0 0 25 40"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use [attr.href]="itemHref(item)"></use>
          </svg>
        }
        <div [hidden]="!vm.isNavExpanded" class="nav-menu-text">
          {{ item.title }}
        </div>
      </a>
    }
  }
  <a
    kendoDialogContainer
    class="navMenuLink logout-div nav-menu-mri-icon-anchor"
    [ngClass]="{ expanded: vm.isNavExpanded }"
    (click)="logout()"
    title="Sign out"
    attr.aria-label="Sign out"
  >
    <div class="mri-icon-container">
      <mri-icon icon="logout" class="mri-icon__svg nav-menu-mri-icon"></mri-icon>
    </div>
    <div class="nav-menu-text manual-button" [hidden]="!vm.isNavExpanded">Sign Out</div>
  </a>
  <div class="link-container">
    <a class="chevron-expand-link" (click)="toggleNavExpansion()">
      <div class="chevron-expand">
        @if (vm.isNavExpanded) {
          <mri-icon icon="chevron-left" class="icon-expand chevron-left"></mri-icon>
        } @else {
          <mri-icon icon="chevron-right" class="icon-expand chevron-right"></mri-icon>
        }
      </div>
    </a>
  </div>
}
