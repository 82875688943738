import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreUiModule } from '@mri-platform/shared/core-ui';
import { UiMainNavBarModule } from '@mri-platform/shared/ui-main-nav-bar';
import { UI_DATALIST } from '@mri-platform/ui-datalist';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { ClientSwitchListPageComponent } from './containers/client-switch-list/client-switch-list-page.component';
import { PageFooterComponent } from './containers/footer/page-footer.component';
import { PageLoadingComponent } from './containers/page-loading.component';
import { ShellComponent } from './containers/shell/shell.component';
import { UnauthorizedPageComponent } from './containers/unauthorized-page.component';
import { UnhandledErrorPageComponent } from './containers/unhandled-error-page.component';
import { MenuItemService } from './ui-services';

export abstract class ShellModuleConfig {
  abstract menuItemService: Type<MenuItemService>;
}

const PUBLIC_DECLARATIONS = [PageFooterComponent, PageLoadingComponent, ShellComponent, ClientSwitchListPageComponent];

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'unauthorized',
        component: UnauthorizedPageComponent
      }
    ]),
    CommonModule,
    CoreUiModule,
    UiMainNavBarModule,
    ...UI_DATALIST,
    ButtonsModule
  ],
  declarations: [UnauthorizedPageComponent, UnhandledErrorPageComponent, PUBLIC_DECLARATIONS],
  exports: [PUBLIC_DECLARATIONS],
  providers: [{ provide: ICON_SETTINGS, useValue: { type: 'font' } }]
})
class ShellModule {}

// make public (ie available outside of this library)...
export { ShellModule as SharedShellModule, ShellComponent, UnhandledErrorPageComponent };
