import { Routes } from '@angular/router';
import { AppRoutes } from '@mri-platform/dsg/core';
import { HomePageComponent, ShellComponent } from '@mri-platform/dsg/shell';
import { UnhandledErrorPageComponent } from '@mri-platform/shared/shell';
import { AuthGuard } from '@mri/angular-wfe-proxy-oidc';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: ShellComponent,
    children: [
      {
        path: 'about',
        outlet: 'modal',
        loadChildren: () => import('@mri-platform/dsg/about')
      },
      {
        path: 'onboard-config',
        loadChildren: () =>
          import('@mri-platform/dsg/client-onboard-config').then(module => module.ClientOnboardConfigModule)
      },
      AppRoutes.Home.applyTo({ component: HomePageComponent }),
      AppRoutes.Error.applyTo({ component: UnhandledErrorPageComponent }),
      {
        path: 'dashboards',
        loadChildren: () => import('@mri-platform/dsg/dashboards').then(module => module.DashboardsModule)
      },
      {
        path: 'switch-client',
        outlet: 'drawer',
        loadChildren: () => import('@mri-platform/dsg/client-switch').then(module => module.ClientSwitchModule)
      },
      {
        path: 'user-settings',
        loadChildren: () => import('@mri-platform/dsg/user-settings').then(module => module.UserSettingsModule)
      },
      {
        path: 'dashboard-management',
        loadChildren: () =>
          import('@mri-platform/dsg/dashboard-management').then(module => module.DashboardManagementModule)
      },
      {
        path: 'reconciliation',
        loadChildren: () => import('@mri-platform/dsg/reconciliation').then(module => module.ReconciliationModule)
      },
      { path: '', redirectTo: AppRoutes.Home.path, pathMatch: 'full' }
    ]
  }
];
