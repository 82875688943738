<mri-shared-drawer
  [hideHeader]="true"
  [position]="'left'"
  [isOpen]="this.isOpen"
  [clickOverlayToClose]="true"
  [overlayContainerOnly]="true"
  (closeDrawer)="close()"
>
  <div *mriSharedDrawerBody id="menuWindow" class="menuWindow">
    @if (this.activeItem; as contents) {
      <div id="subMenuWindow" class="subMenuWindow">
        <h4 class="mri-heading mri-heading--4 mri-header menu-header">{{ contents.header }}</h4>
        <div class="MenuColumn">
          <div class="menuGroup">
            @for (item of contents.links; track item) {
              <ng-container
                [ngTemplateOutlet]="isSection(item) ? drawerSection : drawerLink"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-container>
            }
          </div>
        </div>
      </div>
    }
  </div>
</mri-shared-drawer>

<ng-template #drawerSection let-section>
  <div class="menuItem">{{ section.text }}</div>
  <div class="subMenuGroup">
    @for (link of section.links; track link) {
      <ng-container [ngTemplateOutlet]="drawerLink" [ngTemplateOutletContext]="{ $implicit: link }"></ng-container>
    }
  </div>
</ng-template>

<ng-template #drawerLink let-link>
  <div class="menuItem menuAction">
    <a class="mri-link" role="link" [title]="link.text" (click)="routeToUrl(link)">
      {{ link.text }}
    </a>
  </div>
</ng-template>
