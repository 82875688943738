import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TELEMETRY_INITIALIZER } from '@mri-platform/angular-app-insights';
import {
  HttpClientBlobErrorInterceptor,
  HttpClientErrorEnrichmentInterceptor,
  sanitizeHttpClientProblemDetails
} from '@mri-platform/angular-error-handling';
import { EntityStateModule } from '@mri-platform/dsg/entity-state';
import { DSGShellModule } from '@mri-platform/dsg/shell';
import {
  AppBreezeIntegrationModule,
  sanitizeBreezeClientSaveError,
  sanitizeBreezeServerError
} from '@mri-platform/shared/app-breeze-integration';
import {
  ApiVersionInterceptor,
  AppCoreIntegrationModule,
  AppUserPermissionService,
  ImpersonateClientSwitchService
} from '@mri-platform/shared/app-core-integration';
import { AppInsightsIntegrationModule } from '@mri-platform/shared/app-insights-integration';
import { NavigationModule } from '@mri-platform/shared/app-ngrx-integration';
import { ClientSwitchService, environment, SharedCoreModule } from '@mri-platform/shared/core';
import { UserInfoService, UserPermissionService } from '@mri/angular-wfe-proxy-oidc';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxPendoModule } from 'ngx-pendo';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import {
  AppUserInfoService,
  DashboardPageViewTelemetryInitializer,
  FeatureFlagsConfigProvider,
  ignoreIframeError,
  pendoProviders
} from './integration';
import { ROOT_REDUCERS } from './reducers';
import { testingProviders } from './testing';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
      // enableTracing: !environment.production
    }),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: true,
        strictStateSerializability: true
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    AppInsightsIntegrationModule,
    AppCoreIntegrationModule.forRoot({
      errorHandling: {
        sanitizers: [sanitizeHttpClientProblemDetails, sanitizeBreezeServerError, sanitizeBreezeClientSaveError]
      },
      errorLogging: { selectors: [ignoreIframeError] }
    }),
    AppBreezeIntegrationModule.forRoot(),
    NavigationModule,
    DSGShellModule,
    BrowserAnimationsModule,
    EntityStateModule,
    // note: we are NOT supplying settings for pendo here. we instead use pendoProviders below for that
    NgxPendoModule.forRoot({ pendoApiKey: '' })
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    SharedCoreModule.forRoot({ useFeatureFlags: true }).providers ?? [],
    testingProviders,
    { provide: ClientSwitchService, useClass: ImpersonateClientSwitchService },
    { provide: UserPermissionService, useClass: AppUserPermissionService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientErrorEnrichmentInterceptor, multi: true },
    // critical: interceptors that handle errors must appear AFTER HttpClientErrorEnrichmentInterceptor
    { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientBlobErrorInterceptor, multi: true },
    { provide: UserInfoService, useClass: AppUserInfoService },
    { provide: TELEMETRY_INITIALIZER, useClass: DashboardPageViewTelemetryInitializer, multi: true },
    pendoProviders,
    FeatureFlagsConfigProvider
  ]
})
export class AppModule {}
