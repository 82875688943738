import { Injectable } from '@angular/core';
import { AgWidgetConfig } from '@mri-platform/shared/core';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WidgetService {
  constructor(private agWidgetConfig: AgWidgetConfig) {}

  loadWidget$() {
    return from(
      new Promise<boolean>(resolve => {
        if (this.agWidgetConfig.useCdn) {
          // check if the widget script is already loaded (When AG Admin uses the CDN Widget)
          if (document.getElementById('ag-widget-script')) {
            resolve(true);
          } else {
            resolve(this.loadScript(this.agWidgetConfig.cdnUrl, 'ag-widget-script'));
          }
        } else {
          // if we are not using the widget cdn, we should resolve true since the widget is already loaded (AG Admin use case were the angular widget module is imported)
          resolve(true);
        }
      })
    );
  }

  loadAskAiWidget$(cdnUrl: string) {
    return from(this.loadScript(cdnUrl, 'ask-ai-widget-script'));
  }

  loadAskAiFrameWidget$(cdnUrl: string) {
    return from(this.loadScript(cdnUrl, 'ask-ai-frame-widget-script'));
  }

  private loadScript(cdnUrl: string, id: string) {
    return new Promise<boolean>(resolve => {
      const script = document.createElement('script');
      script.src = cdnUrl;
      script.setAttribute('id', id);
      script.async = true;
      script.type = 'module';
      script.onerror = async () => {
        console.error('An error occurred while loading the widget script');
        resolve(false);
      };
      script.onload = async () => {
        resolve(true);
      };
      document.head.appendChild(script);
    });
  }
}
